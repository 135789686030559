@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply font-sans;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply uppercase;
}
